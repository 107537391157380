import { LEADERBOARD_TYPE, ADVANCED_TYPE, PHOTOS_TYPE, VIDEO_TYPE, PARIMUTUEL_TYPE, TEXT_TYPE, TEE_SHEET_TYPE } from './constants'
import { showNotification } from 'Shared/helpers'
import _ from 'lodash'

// INDEX

export const copyToClipboard = (textToCopy, feedbackMessage = (!window.I18n ? '' : window.I18n.t('tv_shows.helpers.copied'))) => {
  const input = document.createElement('input')
  input.value = textToCopy
  document.body.appendChild(input)
  input.select()

  document.execCommand('copy')
  input.remove()
  showNotification(feedbackMessage)
}

// SHOW SETUP

export const ckeditorToolbar = [
  [ 'Undo', 'Redo' ],
  [ 'PasteFromWord' ],
  [ 'Font', 'FontSize' ],
  [ 'Bold', 'Italic', 'Underline', 'Strike', 'TextColor', 'BGColor' ],
  [ 'Link', 'Unlink' ],
  [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ],
  [ 'HorizontalRule' ],
  [ 'NumberedList', 'BulletedList', 'Indent', 'Outdent' ],
  [ 'Source' ],
]

export const ckeditorMinimalistToolbar = [
  [ 'Font', 'FontSize' ],
  [ 'Bold', 'Italic', 'Underline', 'TextColor', 'BGColor' ],
  [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight' ],
  [ 'Source' ],
]

export const buildTvShow = (props) => {
  const tvShow = {}
  if (props.general.hasUnsavedChanges) {
    tvShow.general = {
      id: props.general.id,
      name: props.general.name,
      backgroundImage: props.general.backgroundImage,
      sponsorLogos: props.general.sponsorLogos,
      hasUSGATheme: props.general.hasUSGATheme,
    }
  }
  if (props.header.hasUnsavedChanges) {
    tvShow.header = {
      isVisible: props.header.isVisible,
      text: props.header.text,
      height: props.header.height,
      selectedBackground: props.header.selectedBackground,
      backgroundColor: props.header.backgroundColor,
      backgroundImage: props.header.backgroundImage,
      backgroundOpacity: props.header.backgroundOpacity,
      leftLogo: props.header.leftLogo,
      rightLogo: props.header.rightLogo,
    }
  }
  if (props.footer.hasUnsavedChanges) {
    tvShow.footer = {
      isVisible: props.footer.isVisible,
      text: props.footer.text,
      height: props.footer.height,
      selectedBackground: props.footer.selectedBackground,
      backgroundColor: props.footer.backgroundColor,
      backgroundImage: props.footer.backgroundImage,
      backgroundOpacity: props.footer.backgroundOpacity,
    }
  }
  if (props.slides.hasUnsavedChanges) {
    tvShow.slides = props.slides.tvShowSlides.map( slide => {
      return {
        id: slide.id,
        position: slide.position,
      }
    } )
  }

  return tvShow
}

export const buildLibrarySettings = (props) => {
  return {
    library: {
      setTemplate: props.setTemplate,
      autoIncludeOption: props.autoIncludeOption,
      rounds: Object.keys(props.rounds).map( roundId => {
        return {
          id: roundId,
          checked: props.rounds[roundId].checked,
        }
      }),
    },
  }
}

export const initialSlideState = (slideType) => {
  switch (slideType){
    case LEADERBOARD_TYPE:
      return {
        type: LEADERBOARD_TYPE,
        isVisible: true,
        roundId: 0,
        tournamentId: 0,
        divisionIds: [],
        flightIdsAndValuesByDivisionId: {},
        animation: 0,
        scrollRate: 5,
        backgroundImage: '',
      }
    case ADVANCED_TYPE:
      return {
        type: ADVANCED_TYPE,
        isVisible: true,
        scrollRate: 5,
      }
    case PHOTOS_TYPE:
      return {
        type: PHOTOS_TYPE,
        description: '',
        isVisible: true,
        roll: [],
        scrollRate: 5,
      }
    case VIDEO_TYPE:
      return {
        type: VIDEO_TYPE,
        isVisible: true,
        videoId: '',
      }
    case TEXT_TYPE:
      return {
        type: TEXT_TYPE,
        isVisible: true,
        scrollRate: 5,
        text: '',
        bgColor: '#333333',
        backgroundOpacity: 0,
      }
    case PARIMUTUEL_TYPE:
      return {
        type: PARIMUTUEL_TYPE,
        isVisible: true,
        scrollRate: 5,
        betting_type: null,
        tournamentId: null,
      }
    case TEE_SHEET_TYPE:
      return {
        type: TEE_SHEET_TYPE,
        isVisible: true,
        scrollRate: 5,
      }
    default:
      return {
        type: 'none',
      }
  }
}

const stringToBool = (string) => string === 'true'

export const slideToConfigurationAdapter = (slide) => {
  let configuration = {
    slideId: slide.id,
    description: slide.description,
    type: slide.slide_type,
    isVisible: slide.visible,
    animation: slide.animation,
    scrollRate: slide.scroll_rate,
    backgroundImage: slide.background,
  }

  switch (slide.slide_type) {
    case LEADERBOARD_TYPE:
      configuration = {
        ...configuration,
        roundId: slide.settings.roundId,
        tournamentId: slide.settings.tournamentId,
        divisionIds: slide.settings.divisionIds,
        flightIdsAndValuesByDivisionId: slide.settings.flightIdsAndValuesByDivisionId,
        backgroundOpacity: parseInt(slide.settings.backgroundOpacity),
      }
      break
    case ADVANCED_TYPE:
      configuration = {
        ...configuration,
        gridItems: {
          a: Object.values(slide.settings.containedSlidesById).filter( region => region.x === '0' )[0],
          b: Object.values(slide.settings.containedSlidesById).filter( region => region.x === '1' )[0],
        },
      }
      break
    case PHOTOS_TYPE:
      configuration = {
        ...configuration,
        backgroundOpacity: parseInt(slide.settings.backgroundOpacity),
        roll: slide.settings.roll,
        animation: parseInt(slide.settings.animation),
      }
      break
    case VIDEO_TYPE:
      configuration = {
        ...configuration,
        backgroundOpacity: parseInt(slide.settings.backgroundOpacity),
        videoId: slide.settings.videoId,
      }
      break
    case TEXT_TYPE:
      configuration = {
        ...configuration,
        backgroundOpacity: parseInt(slide.settings.backgroundOpacity),
        text: slide.settings.text,
        bgColor: slide.settings.bgColor,
      }
      break
    case PARIMUTUEL_TYPE:
      configuration = {
        ...configuration,
        backgroundOpacity: parseInt(slide.settings.backgroundOpacity),
        betting_type: slide.settings.betting_type,
        tournamentId: slide.settings.tournamentId,
      }
      break
    case TEE_SHEET_TYPE:
      configuration = {
        ...configuration,
        backgroundOpacity: parseInt(slide.settings.backgroundOpacity),
        options: {
          options_tee_sheet_selected_round: slide.settings.options_tee_sheet_selected_round,
          options_tee_sheet_allow_golfers_to_change: stringToBool(slide.settings.options_tee_sheet_allow_golfers_to_change),
          options_tee_sheet_player_index: stringToBool(slide.settings.options_tee_sheet_player_index),
          options_tee_sheet_show_blinds: stringToBool(slide.settings.options_tee_sheet_show_blinds),
          options_tee_sheet_fullplayerch: stringToBool(slide.settings.options_tee_sheet_fullplayerch),
          options_tee_sheet_playerchat: stringToBool(slide.settings.options_tee_sheet_playerchat),
          options_tee_sheet_playerchatvalue: slide.settings.options_tee_sheet_playerchatvalue,
          options_tee_sheet_include_cumulativescores: stringToBool(slide.settings.options_tee_sheet_include_cumulativescores),
          v2_tournament_event_ids: slide.settings.v2_tournament_event_ids,
          options_metadatumid: slide.settings.options_metadatumid,
          options_tee_sheet_default_mode: slide.settings.options_tee_sheet_default_mode,
          options_tee_sheet_onerowteetime: stringToBool(slide.settings.options_tee_sheet_onerowteetime),
          options_tee_sheet_displayspecificcourses: stringToBool(slide.settings.options_tee_sheet_displayspecificcourses),
          options_tee_sheet_selected_courses: slide.settings.options_tee_sheet_selected_courses,
          options_tee_sheet_courses_order: slide.settings.options_tee_sheet_courses_order,
          options_tee_sheet_abbreviations: stringToBool(slide.settings.options_tee_sheet_abbreviations),
          selectedTournaments: slide.settings.selectedTournaments,
        },
      }
      break
  }

  return configuration
}

export const buildNewShowSlideshowPath = (path, tvShowId) => {
  return path.replace(/tv_shows\/0\//gi, `tv_shows/${tvShowId}/`)
}

// SLIDESHOW

export const interpretPlaceholders = (text, availablePlaceholders) => {
  const { extraFields, extraFieldsData } = availablePlaceholders
  let interpretedText = text
  if (availablePlaceholders.placeholderValues) {
    availablePlaceholders = availablePlaceholders.placeholderValues
  }

  interpretedText = interpretedText.replace(/%event.name%/gi, _.get(availablePlaceholders, 'eventName', ''))
  interpretedText = interpretedText.replace(/%tournament.name%/gi, _.get(availablePlaceholders, 'tournamentName', ''))
  interpretedText = interpretedText.replace(/%round.date%/gi, _.get(availablePlaceholders, 'roundDate', ''))
  interpretedText = interpretedText.replace(/%division.name%/gi, _.get(availablePlaceholders, 'divisionName', ''))
  if (extraFields && extraFieldsData) {
    extraFields.map(([ , placeholder ], index) => {
      interpretedText = interpretedText.replace(placeholder, extraFieldsData[index])
    })
  }
  
  return interpretedText
}

// TICKER

export const buildTickerText = (leaderRows, scoreFormat) => {
  const leaderNames = buildTickerLeaderNames($(leaderRows).find('.name a')
                                                          .not('.name .usga-theme a')
                                                          .get()
  )
  if (leaderNames.length === 0) {
    return ''
  }
  
  const verb = leaderNames.includes('and') ? (!window.I18n ? '' : window.I18n.t('tv_shows.helpers.tied')) : (!window.I18n ? '' : window.I18n.t('tv_shows.helpers.leading'))
  const preposition = [ 'Skins', 'Stableford', 'RingersStableford', 'Quota' ].includes(scoreFormat) ? (!window.I18n ? '' : window.I18n.t('tv_shows.helpers.with')) : (!window.I18n ? '' : window.I18n.t('tv_shows.helpers.at'))
  const score = $(leaderRows).find('.score, .total')
                            .first()
                            .text()
                            .trim()
  const scoreUnit = buildTickerScoreUnit(score, scoreFormat)

  return [ leaderNames, ' ', verb, ' ', preposition, ' ', score, ' ', scoreUnit ].join('').trim()
}

const buildTickerLeaderNames = (anchorTagsWithNames) => {
  let leaderNames = ''
  const names = anchorTagsWithNames.map( name => $(name).text()
                                                        .trim())
  if (names.length > 1) {
    leaderNames = `${names.slice(0, names.length - 1).join(', ')} ${!window.I18n ? '' : window.I18n.t('tv_shows.helpers.and')} ${names[names.length - 1]}`
  } else if (names.length === 1) {
    leaderNames = [ names[0] ]
  }

  return leaderNames
}

const buildTickerScoreUnit = (score, scoreFormat) => {
  let scoreUnit = ''

  switch (scoreFormat) {
    case 'Skins':
      scoreUnit = !window.I18n ? '' : window.I18n.t('tv_shows.helpers.skin')
      break
    case 'Stableford':
    case 'RingersStableford':
      scoreUnit = !window.I18n ? '' : window.I18n.t('tv_shows.helpers.stableford_point')
      break
    case 'Quota':
      scoreUnit = !window.I18n ? '' : window.I18n.t('tv_shows.helpers.in_relation_to_par')
      break
  }

  if ( score !== 1 && [ 'Skins', 'Stableford', 'RingersStableford' ].includes(scoreFormat) ) {
    scoreUnit += 's'
  }

  return scoreUnit
}

// ADVANCED SLIDE

export const gridItemCompare = (item1, item2) => {
  if ( item1.y < item2.y ) {
    return -1
  } else if (item1.y > item2.y) {
    return 1
  } else if (item1.x < item2.x) { // item1.y === item2.y
    return -1
  } else if ( item1.x > item2.x ) { // item1.y === item2.y
    return 1
  }

  return 0
}

export const computeLayout = ( slidesLayout ) => {
  return {
    xs: Object.entries(slidesLayout).map( ([ layoutIndex, layoutSettings ]) => {
      return {
        i: layoutIndex,
        x: parseInt(layoutSettings.x),
        y: parseInt(layoutSettings.y),
        w: 1,
        h: 1,
      }
    } ),
  }
}

export const computeHeight = ( tvShowSettings, blueBox, advancedSlideHeaderHeight = 0 ) => {
  const headerHeight = Object.keys(tvShowSettings.header_settings).length > 0 ? parseInt(tvShowSettings.header_settings.height) : 0
  const footerHeight = Object.keys(tvShowSettings.footer_settings).length > 0 ? parseInt(tvShowSettings.footer_settings.height) : 0
  const blueBoxTopMargin = Object.keys(blueBox).length > 0 ? blueBox.topMargin : 0
  const blueBoxBottomMargin = Object.keys(blueBox).length > 0 ? blueBox.bottomMargin : 0
  advancedSlideHeaderHeight = advancedSlideHeaderHeight ? advancedSlideHeaderHeight + 30 : 0

  return headerHeight + footerHeight + blueBoxTopMargin + blueBoxBottomMargin + advancedSlideHeaderHeight + 45
}

// Returns true for tournaments to which the USGA theme will be applied.
export const isUsgaThemeRestrictedTournament = (slide, currentDivisionPosition) => {
  if ((slide.divisions?.length || 0) === 0 || currentDivisionPosition >= slide.divisions.length) {
    return false
  }
  const scoreFormat = slide.divisions[currentDivisionPosition]?.scoreFormat || ''
  const collegeScoring = slide.divisions[currentDivisionPosition]?.collegeScoring || false
  const competitionName = slide.tournamentCompetitionName?.toLowerCase() || ''
  const ballName = slide.tournamentBallName?.toLowerCase() || ''
  return (
    scoreFormat === 'Stroke' &&
      (
        !competitionName.includes('team')
          || competitionName.includes('team') && ballName.includes('best ball')
          || collegeScoring
      )
    || isMatchPlay(slide, currentDivisionPosition)
  )
}

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null
}

export const isListViewBracket = (slide) => {
  return slide.displayType === 'brackets' && slide.tvDisplayMode === 'list of matches'
}

export const isMatchPlay = (slide, currentDivisionPosition) => {
  const scoreFormat = slide.divisions[currentDivisionPosition]?.scoreFormat || ''
  return (scoreFormat === 'Match' && !slide.isMrt) || isListViewBracket(slide)
}

export const fixHeaderTranslationUsgaTheme = (htmlResult, applyUSGATheme) => {
  return htmlResult.replace(
    /window\.glg\.translation\.tournament2_event\(false, I18n\.locale\)/gi,
    `window.glg.translation.tournament2_event(${applyUSGATheme}, I18n.locale)`
  ).trim()
}

export const shrinkFontSize = (element, checkWidthExceedsLimit, largestElem, maxAllowedWidth, minFontSize = 10) => {    
  while (checkWidthExceedsLimit(largestElem, maxAllowedWidth)) {
    let currentFontSize = $(element).css('font-size')
    currentFontSize = parseInt(currentFontSize)
    if (currentFontSize <= minFontSize) {
      break
    }
    $(element).css('font-size', currentFontSize - 1)
  }
}

export const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = src
    img.onload = resolve
    img.onerror = reject
  })
}

export const preloadImages = (imagesArray) => {
  const promises = imagesArray.map((src) => preloadImage(src))
  return Promise.all(promises)
}
